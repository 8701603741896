import lang from 'constants/language';
import { ausPhoneRegExp } from 'constants/regex';

import { replaceString } from 'utils/commonHelpers';
import { emailValidation } from 'utils/email-validation.helper';
import * as Yup from 'yup';

export const saveBusinessValidation = Yup.object().shape({
    businessName: Yup.string()
        .min(3, replaceString(lang.MIN_NAME_VALIDATION, '{field}', 'Business'))
        .max(60, replaceString(lang.MAX_TITLE_VALIDATION, '{field}', 'Business'))
        .required(replaceString(lang.REQUIRED_VALIDATION, '{field}', 'Business')),
    abn: Yup.string()
        .matches(/^\d{11}$/, lang.INVALID_ABN)
        .required(replaceString(lang.REQUIRED_VALIDATION, '{field}', 'Abn')),
    primaryLocation: Yup.object().shape({
        name: Yup.string()
            .required(replaceString(lang.REQUIRED_VALIDATION, '{field}', 'Location name'))
            .min(3, replaceString(lang.MIN_NAME_VALIDATION, '{field}', 'Location name'))
            .max(30, replaceString(lang.MAX_NAME_VALIDATION, '{field}', 'Location name'))
            .typeError(lang.FIELD_REQUIRED('Location name')),
        locationAddress: Yup.object()
            .shape({
                displayLocation: Yup.string().required(replaceString(lang.REQUIRED_VALIDATION, '{field}', 'Location address'))
            })
            .typeError(lang.FIELD_REQUIRED('Location address')),
        email: emailValidation(lang.INVALID_EMAIL).label('Email').required().typeError(lang.FIELD_REQUIRED('Email')),
        phone: Yup.string()
            .label('Phone number')
            .required()
            .matches(ausPhoneRegExp, lang.INVALID_PHONE)
            .typeError(lang.FIELD_REQUIRED('Phone number'))
    }),

    otherLocations: Yup.array().of(
        Yup.object().shape({
            name: Yup.string()
                .required(replaceString(lang.REQUIRED_VALIDATION, '{field}', 'Location name'))
                .min(3, replaceString(lang.MIN_NAME_VALIDATION, '{field}', 'Location name'))
                .max(30, replaceString(lang.MAX_NAME_VALIDATION, '{field}', 'Location name'))
                .typeError(lang.FIELD_REQUIRED('Location name')),
            locationAddress: Yup.object()
                .shape({
                    displayLocation: Yup.string().required(replaceString(lang.REQUIRED_VALIDATION, '{field}', 'Location address'))
                })
                .typeError(lang.FIELD_REQUIRED('Location address')),
            email: emailValidation(lang.INVALID_EMAIL).label('Email').required().typeError(lang.FIELD_REQUIRED('Email')),
            phone: Yup.string()
                .label('Phone number')
                .required()
                .matches(ausPhoneRegExp, lang.INVALID_PHONE)
                .typeError(lang.FIELD_REQUIRED('Phone number'))
        })
    )
});

export const saveUserProfileValidation = Yup.object().shape({
    firstName: Yup.string()
        .min(3, replaceString(lang.MIN_NAME_VALIDATION, '{field}', 'Firstname'))
        .max(60, replaceString(lang.MAX_TITLE_VALIDATION, '{field}', 'Firstname'))
        .required(replaceString(lang.REQUIRED_VALIDATION, '{field}', 'Firstname')),
    lastName: Yup.string()
        .min(3, replaceString(lang.MIN_NAME_VALIDATION, '{field}', 'Lastname'))
        .max(60, replaceString(lang.MAX_TITLE_VALIDATION, '{field}', 'Lastname'))
        .required(replaceString(lang.REQUIRED_VALIDATION, '{field}', 'Lastname')),
    email: emailValidation(lang.INVALID_EMAIL).label('Email').required().typeError(lang.FIELD_REQUIRED('Email')),
    phone: Yup.string()
        .label('Phone number')
        .required()
        .matches(ausPhoneRegExp, lang.INVALID_PHONE)
        .typeError(lang.FIELD_REQUIRED('Phone number')),
    dateOfBirth: Yup.date().nullable()
});
